// * {
//     margin: 0;
//     padding: 0;
//     box-sizing: border-box;
//   }
  
.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.menu-items {
  position: relative;
  font-size: 14px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.menu-items button .dot-before {
  margin-left: 3px;
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0px 59px 0px 0px;
  font-weight: 600;
  font-size: 14px;
  color: #FFF;
}
.nav-menu .menu-items:last-child > a, .nav-menu .menu-items:last-child button {
  padding-right: 0px;
}
.dropdown .menu-items:last-child > a,  .nav-menu .menu-items:last-child button {
  padding-right: 1rem;

}
.menu-items a:hover,
.menu-items button:hover {
  background-color: transparent;
  color: #0E97FF;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: auto;
  left: 0;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 200px;
  padding: 6px 0px;
  margin: 1px 0px 0px 0px;
  list-style: none;
  background: #1B2132;
  border-radius: 10px;
  display: none;
  border-right: 10px transparent;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
  margin-left: 2px;
}
.dropdown .menu-items > a, .dropdown .menu-items button {
  text-align: left;
  padding: 0.7rem 1.3rem;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
  background-color: transparent;
}

.dropdown .menu-items a:hover,
.dropdown .menu-items button:hover {
  background-color: transparent;
  color: #0E97FF;
}

/* content */

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
  margin-right: 10px;
}

.dot-after {
  background: linear-gradient(0deg, #0E97FF, #0E97FF), linear-gradient(0deg, #0E97FF, #0E97FF), #0E97FF;
  height: 4px;
  width: 4px;
  border-radius: 100%;
  margin-left: auto;
}

.dot-before {
  border: 1px solid #0E97FF;
  background: transparent;
  height: 4px;
  width: 4px;
  border-radius: 100%;
  margin-right: -10px;
  opacity: 0;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.menu-items>button:hover .dot-before, .menu-items>a:hover .dot-before {
  opacity: 1;
  margin-right: 10px;
}
@media(min-width: 1280px) and (max-width: 1440px){
  .menu-items > a, .menu-items button {
    padding: 0px 41px 0px 0px;
    font-size: 13px;
  }
}
@media(min-width: 1600px){
  .dropdown {
    min-width: 265px;
    padding: 7px 0px;
  }
}
