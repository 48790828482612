.navbar-transparent-fixed {
    background: transparent !important;
    box-shadow: none !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    border-bottom: 1px solid transparent;
}
.display-emp button{
    font-family: 'Poppins', sans-serif !important;
    background: #FFFFFF !important;
    border-radius: 10px !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    color: #0E97FF !important;
    padding: 7px 23px !important;
}
.navbar-white-fixed {
    background: #FFF !important;
    box-shadow: none !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    border-bottom: 1px solid rgb(14 151 255 / 20%);
    box-shadow: 0px 0px 14px rgb(14 151 255 / 9%) !important;
}
.navbar-white-fixed .display-emp button{
    background: #FFFFFF !important;
    border: 1px solid #D8DCDF !important;
    box-shadow: none !important;
}
.navbar-white-fixed .menus > .menu-items > a, .navbar-white-fixed .menus > .menu-items > button {
    color: #000 !important;
}
.navbar-white-fixed .menus > .menu-items > a:hover, .navbar-white-fixed .menus > .menu-items > button:hover {
    color: #0E97FF !important;
}
.navbar-inner {
    width: 100%;
}
.navbar-center{
    display: flex;
    align-items: center;
    padding: 17px 0px 12px;
}
.profile-nav{
    display: flex;
    align-items: center;
}
.profile-nav>div {
    margin-left: 0px;
}
.search-icon-header{
    position: relative;
    top: 2px;
}
.sidebar-list-text span{
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #212121;
}
.sidebar-list-text:hover span{
    color: #0E97FF;
}
.sidebar-collapse{
    background: rgba(14, 151, 255, 0.04);
}
.subitem-pad-left{
    padding-left: 20px;
}
.sidebar-drawer{
    width: 270px;
    padding-left: 10px;
}
.emp-corner-mobile{
    margin: 8px auto 0px;
    border-top: 1px solid #e0dada;
    width: 100%;
    padding: 20px 20px 0px;
    box-sizing: border-box;
}
.emp-corner-mobile button{
    background: rgb(14, 151, 255) !important;
    border-radius: 10px !important;
    color: #fff !important;
    font-weight: 400 !important;
    font-size: 13px !important;
    display: block !important;
    margin: 0px 0px !important;
    width: 100% !important;
    text-align: center !important;
    box-shadow: none !important;
    padding: 9px 8px !important;
}
.left-social-mobile ul{
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 8px 0px 0px;
    padding: 10px 0px;
    justify-content: center;
}
.left-social-mobile ul li{
    margin-right: 10px;
    margin-bottom: 10px;
}
.left-social-mobile ul li:last-child{
    margin-right: 0px;
}
.login-btn{
    background-color: rgb(0, 0, 0) !important;
    color: rgb(255, 255, 255) !important;
    font-weight: 400 !important;
    font-size: 15px !important;
    padding: 9px 18px 4px !important;
    border-radius: 0px !important;
    border: 1px solid rgb(255, 255, 255) !important;
}
.login-btn:hover{
    background-color: #0E97FF !important;
    border: 1px solid #0E97FF !important;
}
.top-bar-contct {
    background: #1B2132;
    padding: 12px 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 2;
}    
.top-bar-contact-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0px;
    padding: 0px;
    align-items: center;
    list-style-type: none;
}
.top-bar-contact-list li {
    margin-right: 20px;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 400;
    display: flex;
    align-items: initial;
}
.top-bar-contact-list li:last-child {
    margin-right:0px !important;
}
.top-bar-contact-list .top-bar-contct-icon{
    margin-right: 10px;
}
.position-fixed{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 5;
}
.header-navbar-subpage{
    border-bottom: 1px solid rgb(14, 151, 255, 0.20);
    box-shadow: 0px 0px 14px rgb(14, 151, 255, 0.09) !important;
}
.sub-page-top-header-spacer{
    height: 138px;
}
.header-navbar-subpage-top{
    background-color: #FFFFFF !important;
    box-shadow: none !important;
    position: fixed !important;
    left: 0px !important;
    top: 45px !important;
    width: 100% !important;
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    z-index: 50 !important;
}
.header-navbar-subpage-top .navbar-center{
    padding: 12px 0px 8px;
}
.header-navbar-subpage-top-fixed{
    transition: all 0.5s ease !important;
    -webkit-transition: all 0.5s ease !important;
    background-color: #FFFFFF !important;
    position: fixed !important;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 50 !important;
}
.header-navbar-subpage .menus > .menu-items > a, .header-navbar-subpage .menus > .menu-items > button {
    color: #000 !important;
}
.header-navbar-subpage .menus > .menu-items > a:hover, .header-navbar-subpage .menus > .menu-items > button:hover {
    color: #0E97FF !important;
}
.header-navbar-subpage .display-emp button{
    background: #FFFFFF !important;
    border: 1px solid #D8DCDF !important;
    box-shadow: none !important;
}
.drawer-outer{
    padding-left: 20px;
}
.drawer-logo{
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.drawer-logo img{
    width: 60px;
}
@media (max-width: 1280px){
    .top-bar-contct {
        display: none;
    }
    .sub-page-top-header-spacer{
        height: 74px;
    }
    .header-navbar-subpage-top{
        top: 0px !important;
    }
    .navbar-center{
        padding: 12px 0px 8px !important;
    }
    .display-emp{
        display: none;
    }
    .contact-bar-off{
        display: none !important;
    }
}
@media(max-width: 1199px){
    .navbar-transparent {
        background: rgba(0, 0, 0, .69) !important;
        box-shadow: none !important;
    }
    
    .login-btn {
        font-size: 12px !important;
        padding: 9px 12px 6px !important;
    }
}